/* all */
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.flex-vertical-center-start {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
}

/* OrderAiEdit.tsx */


/* OrderItemTableRow.tsx */
.hoverIcon {
  transition: color 0.15s ease;
}

.hoverIconRed:hover {
  color: #dc3545;
}

.hoverIconBlue:hover {
  color: #3546dc;
}

.selected-items-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  margin-left: 0.75rem;
  column-gap: 0.75rem;
  flex-wrap: wrap;
  row-gap: 0.25rem;

  @media (max-width: 576px) {
    width: min-content;
  }
}