/* ConversationContainer */
/* class container for the whole component */
.conversation-container {
  margin: 0.1rem;
  position: relative;
}

.close-conversation-button {
  position: absolute;
  right: 1rem;
  transform: translateY(-10%);
  border-radius: 4px;
  width: 25px;
  height: 25px;
  padding: 4px;
}

.close-conversation-button:hover {
  cursor: pointer;
}

.conversation-container .messages-header {
  display: flex;
  margin: 0 0.5rem 0.5rem;
  text-transform: uppercase;
  justify-content: center;
  padding: 0.5rem 1.5rem;
  border-bottom: 1px solid rgb(219, 219, 219);
  align-self: center;
  border: 1px solid gray;
  border-radius: 0.5rem;
  background: #373938;
  color: honeydew;
  text-shadow: 1px 1px 4px #00000050;
}

.conversation-container .messages-container {
  display: flex;
  max-height: 75vh;
  overflow-y: auto;
  padding-inline: 0.75rem;
}

/* conversationMessageBubble */
.conversation-container {
  margin: 0.1rem;
  position: relative;

  .messages-header {
    display: flex;
    margin: 0 0.5rem 0.5rem;
    text-transform: uppercase;
    justify-content: center;
    padding: 0.5rem 1.5rem;
    border-bottom: 1px solid rgb(219, 219, 219);
    align-self: center;
    border: 1px solid gray;
    border-radius: 0.5rem;
    background: #373938;
    color: honeydew;
    text-shadow: 1px 1px 4px #00000050;
  }

  .messages-container {
    display: flex;
    max-height: 75vh;
    overflow-y: auto;
    padding-inline: 0.75rem;
  }

  .message-bubble {
    $messageBubble: &;

    display: flex;
    flex-direction: column;
    max-width: 90%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 0.1rem 0.05rem rgba(0, 0, 0, 0.15);
    margin-bottom: 0.5rem;

    &.email {
      max-width: none;
      width: 100%;
    }

    &.user-message {
      text-align: left;
      align-self: start;

      &.whatsapp {
        background: linear-gradient(45deg, #25d36666, #25d36622);
      }
      &.telegram {
        background: linear-gradient(45deg, blue, lightblue);
      }
      &.email {
        background: linear-gradient(45deg, #4285f466, #4285f422);
      }
    }

    &.otb-message {
      background: linear-gradient(135deg, #5555ff22, #5555ff66);

      // right align bubble if messaging app
      &.whatsapp,
      &.telegram {
        text-align: right;
        align-self: end;
      }
    }

    // Info box
    @at-root {
      #{$messageBubble} .info {
        color: black;
        font-size: 0.8rem;
        margin-top: 0.2rem;
        width: auto;

        @at-root {
          #{$messageBubble}.whatsapp .info,
          #{$messageBubble}.telegram .info {
            border-top: 1px solid #888;
            font-style: italic;

            &.otb-message {
              align-self: end;
            }
          }

          #{$messageBubble}.email .info {
            border-bottom: 1px solid #888;
            margin-bottom: 0.5em;
          }
        }

        &.user-message {
          align-self: start;
        }
      }
    }
  }

  .file-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0.2rem auto;
    gap: 0.5rem;

    .audio {
      border-radius: 2rem;
    }
  }

  .image {
    position: relative;
    width: fit-content;

    > button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      background: none;
      background-color: black;
      outline: none;
      border: none;
      color: white;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.2);
      }
    }
  }

  .source-order-img {
    width: 8rem;
    height: 12rem;
    object-fit: cover;
    border-radius: 1rem;
    background: black;
    filter: drop-shadow(0 0 5px #0003);
  }

  button.file {
    color: blue;
    background: none;
    border: none;
    outline: none;
    text-align: start;
    cursor: pointer;

    &:hover {
      color: darkmagenta;
    }
  }

  .order-audio {
    order: 1;
    flex: 1 1;
  }

  .order-image {
    order: 2;
    flex: 1 1 100px;
  }

  .order-application {
    order: 3;
    flex: 1 1;
  }
}
