// Custom before theme configuration
@import 'themes/_theme_before.scss';

@import '~bootstrap/scss/bootstrap';

body {
  font-size: 0.875rem;
  overflow-y: hidden;
  /* display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5; */
}

#root {
  width: 100%;
}

// This was old and probably not needed anymore. It was preventing resizing of icons with classes
// .feather,
// .svg-inline--fa {
//   width: 16px;
//   height: 16px;
//   vertical-align: text-bottom;
// }

/*
 * Generic components
 */
.page-title {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/*
 * Content
 */

[role='main'] {
  padding-top: 133px; /* Space for fixed navbar */
}

@media (min-width: 768px) {
  [role='main'] {
    padding-top: 64px; /* Space for fixed navbar */
  }
}

/*
 * Navbar
 */

.header-title {
  color: white;
  font-size: 1rem;
}

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.header-logo {
  height: 40px;
  pointer-events: none;
  object-fit: contain;
}

/*
* Login
*/

.login-logo {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}
.popover {
  position: absolute;
  top: 300px;
  left: 771px;
  z-index: 1060;
  display: block;
  max-width: 540px;
}

// Custom after theme configuration
@import 'themes/_theme_after.scss';